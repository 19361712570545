import { jsx as _jsx } from "preact/jsx-runtime";
/* eslint-disable @typescript-eslint/naming-convention,react/no-danger */
import DOMPurify from "dompurify";
function SectionIntro({ innerHtml }) {
    if (!innerHtml) {
        return null;
    }
    return (_jsx("div", { className: "form__intro", dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(innerHtml) } }));
}
export default SectionIntro;
