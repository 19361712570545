import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { ErrorMessage } from "formik";
import CurrencyInput from "react-currency-input-field";
import SectionIntro from "@components/SectionIntro";
function NumberInput(props) {
    const { field, currencyInputArgs, placeholder, form, sectionIntro, } = props;
    const { setFieldValue } = form;
    const { name, value } = field;
    const handleChange = (newValue) => setFieldValue(name, newValue !== null && newValue !== void 0 ? newValue : "");
    /* Step 3: Estimate of property value */
    return (_jsxs("div", { className: "form__group", children: [_jsx(SectionIntro, { innerHtml: sectionIntro }), _jsxs("div", { className: "", children: [_jsx("label", { htmlFor: name, className: "form__label sr-only", children: "Rough estimate of property value" }), _jsx(CurrencyInput, Object.assign({}, currencyInputArgs, { id: name, name: name, value: value, onValueChange: handleChange, placeholder: placeholder, className: "form__control" }))] }), _jsx(ErrorMessage, { component: "div", className: "form__error", name: name })] }));
}
NumberInput.defaultProps = {
    currencyInputArgs: null,
    sectionIntro: null,
};
export default NumberInput;
